<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            Villa Members
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Member Registration</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div
                    class="d-flex col-12"
                  >
                    <div
                      class="float-start col-6"
                    >
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"
                          ><vue-feather type="search"></vue-feather
                        ></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search2"
                          v-model="search"
                          @keyup="searchData"
                        />
                      </div>
                    </div>
                    <div
                      class="float-end col-6"
                    >
                      <router-link class="btn btn-gradient-primary float-end" to="/add-main-member">Add</router-link>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                        <div class="card-datatable">
                          <table class="dt-column-search table table-responsive">
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody v-if="paginationData && paginationData.data && paginationData.data.length > 0">
                              <tr v-for="(li, index) in paginationData.data"
                                :key="li.id"
                              >
                                <td>{{ index + paginationData.from }}</td>
                                <td>{{ li.date_of_purchase }}</td>
                                <td>
                                  <span v-if="li.member_detail">
                                  {{
                                    li.member_detail?.first_name +
                                    " " +
                                    li.member_detail?.last_name
                                  }}
                                  </span>
                                </td>
                                <td><span v-if="li.member_detail">{{ li.member_detail.mobile }}</span></td>
                                <td>
                                  <span v-if="li.member_detail">
                                  {{
                                    li.member_detail?.address +
                                    ", " +
                                    li.member_detail?.city +
                                    ", " +
                                    li.member_detail?.state +
                                    ", " +
                                    li.member_detail?.zipcode
                                  }}
                                  </span>
                                </td>
                                <td><span v-if="li.villa_master">{{ li.villa_master.number }}</span></td>
                                <td class="d-flex">
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-success rounded"
                                        @click="
                                          [
                                            add('add_member', li.id),
                                            (form.member_id = li.id),
                                          ]
                                        "
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="plus"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                        <span
                                          class="d-flex align-items-center me-1"
                                          >Add User</span
                                        >
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="edit(li.id, 'member')"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="edit"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="deleteDes(li.id, 'member')"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather
                                            type="trash-2"
                                            size="15"
                                          ></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="8" class="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Form from "vform";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "Registration",
  components: {
    Pagination: LaravelVuePagination,
  },
  data() {
    return {
      form: new Form({
        id: null,
        villa_type_id: "",
        villa_id: "",
        date_of_purchase: new Date().toISOString().substr(0, 10),
      }),
      cardNumber: "",
      url: null,
      urlProof: null,
      response: "",
      disabled: false,
      header: [],
      list: [],
      modalName: "Add",
      paginationData: {},
      search: null,
      token: localStorage.getItem("auth_token"),
    };
  },
  async mounted() {
    this.header = [
      "Sr. no.",
      "Date of Purchase",
      "Name",
      "Mobile",
      "Address",
      "Villa",
      "Action",
    ];
    this.getResults();
  },
  methods: {
    async getResults(page = 1) {

      var searchq = "";
      if (this.search) {
        searchq += "&search=" + this.search;
      }

      await axios
        .get(
          process.env.VUE_APP_API_URL + "/member_registration?page=" + page + searchq,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.paginationData = response.data.data;
        });
    },
    deleteDes(id, type) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              process.env.VUE_APP_API_URL + "/member_registration/delete/",
              {
                member: type === "member" ? 1 : 0,
                member_detail: type === "member_detail" ? 1 : 0,
                barcode: 0,
                barcode_id: 1,
                member_detail_id: id,
                member_id: id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${this.token}`,
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                if (type === "member_detail") {
                  this.disabled = false;
                  this.isEdit = true;
                  this.villaError = "none";
                  this.dateError = "none";
                  this.showForm = true;
                  this.showMember = true;
                  this.disabled = false;
                  this.noMember = false;
                  this.orderClass = true;
                  this.modalName = "Edit";
                  this.editSearch = true;
                  this.noEditSearch = false;
                  this.editForm = true;
                  this.edit(this.form.id);
                } else {
                  this.getResults();
                }
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async searchData() {
      setTimeout(() => {
        this.getResults();
      }, 300);
    },
  },
  computed: {},
};
</script>
<style>
.orderClass {
  display: flex;
  flex-direction: column;
}
</style>
